import * as React from 'react';
import { WithTranslation } from 'next-i18next';
import Head from 'next/head';
import * as i18next from 'i18next';
import { JsonLd } from 'react-schemaorg';
// import { WebSite } from 'schema-dts';

interface OwnProps {
  /** The namespace for the translation */
  namespace: string;
  seoContext?: { [key: string]: any };
}

type Props = OwnProps & WithTranslation;

const defaultTranslation = (
  i18n: i18next.i18n,
  namespace: string,
  seoContext?: { [key: string]: any },
  defaultNamespace: string = 'common'
) => (key: string) =>
  i18n.exists(`${namespace}:seo.${key}`)
    ? i18n.t(`${namespace}:seo.${key}`, { seoContext })
    : i18n.t(`${defaultNamespace}:seo.${key}`, { seoContext });

export const SEO: React.FunctionComponent<Props> = ({ i18n, namespace, seoContext }) => {
  const d = defaultTranslation(i18n, namespace, seoContext);
  return (
    <Head>
      <title>{d('title')}</title>
      <meta name="description" content={d('description')} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@cliqueimudei" />
      <meta property="og:title" content={d('og-title')} />
      <meta property="og:description" content={d('og-description')} />
      <meta property="og:image" content={d('og-image')} />
      <meta property="og:url" content={d('og-url')} />
      <meta property="og:site_name" content="CliqueiMudei" />
      <meta property="og:type" content="website" />
      <JsonLd<any>
        item={{
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          name: 'CliqueiMudei',
          url: 'https://cliqueimudei.com',
          sameAs: [
            'https://www.instagram.com/cliqueimudei/',
            'https://www.facebook.com/cliqueimudei/',
            'https://www.linkedin.com/company/cliqueimudei/',
            'https://twitter.com/cliqueimudei',
          ],
          image: d('og-image'),
          description: d('description'),
          // potentialAction: {
          //   '@type': 'SearchAction',
          //   target: 'https://cliqueimudei.com/query/{search_term_string}',
          //   'query-input': 'required name=search_term_string',
          // },
        }}
      />
      <link rel="apple-touch-icon" href={require('@app/public/images/apple-touch-icon.png?url')} />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={require('@app/public/images/android-chrome-192x192.png?url')}
      />
      <link rel="icon" type="image/png" href={require('@app/public/images/favicon.png?url')} />
      <link rel="canonical" href={d('canonical')} />
      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content="#b22e41" />
      <meta
        name="msapplication-TileImage"
        content={require('@app/public/images/android-chrome-192x192.png?url')}
      />
      <meta name="theme-color" content="#b22e41" />
      <meta name="application-name" content="CliqueiMudei" />
      <meta name="theme-color" content="#b22e41" />
    </Head>
  );
};
